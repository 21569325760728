<template>
	<div class="pl-10">
		<div class="title" @click="$emit('choose', tree)">{{ tree.title }}</div>
		<Tree
			v-for="(sub, index) in tree.subordinates || []"
			:key="index"
			:tree="sub"
			@choose="(v) => $emit('choose', v)"
		/>
	</div>
</template>

<script>
	import Tree from "./Tree"
	export default {
		name: "Tree",
		components: { Tree },
		props: { tree: { type: Object, required: true } }
	}
</script>
