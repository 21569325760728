<template>
	<div class="folders">
		<div class="flx gap-2">
			<vSelect
				v-model="selectedUser"
				@search="(v) => (userSearch = v)"
				:options="userList"
				label="label"
				placeholder="Find by User..."
				class="flx-1 ba"
			>
				<template #option="option">
					<span
						>{{ option.lastname }}, {{ option.firstname }}
						<span class="optEmail">{{ option.email }}</span></span
					>
				</template>
				<template #selected-option="{ firstname, lastname }">{{ lastname }}, {{ firstname }}</template>
			</vSelect>
			<vSelect
				v-model="selectedTeam"
				@search="(v) => (teamSearch = v)"
				:options="teamList"
				placeholder="Find by Team..."
				label="label"
				class="flx-1 ba"
			>
				<template #option="option">
					<span
						><span class="optClinicName">[{{ option.orgTitle }}]</span> {{ option.title }}</span
					>
				</template>
				<template #selected-option="{ title, orgTitle }">{{ title }} [{{ orgTitle }}]</template>
			</vSelect>
			<vSelect
				v-model="selectedOrg"
				@search="(v) => (orgSearch = v)"
				:options="orgList"
				label="title"
				placeholder="Find by Organization..."
				class="flx-1 ba"
			/>
		</div>

		<template v-if="folderList.length && (selectedUser || selectedTeam || selectedOrg)">
			<div class="flx aln-cntr mv-10 pl-5">
				<a
					v-if="selectedUser"
					href="#"
					@click.prevent="router.push({ name: 'Users', params: { id: selectedUser.id } })"
				>
					User
					{{ selectedUser.id }}: {{ selectedUser.firstname }} {{ selectedUser.lastname }} &mdash;
					{{ selectedUser.email }}
				</a>
				<template v-else-if="selectedTeam">
					Team
					{{ selectedTeam.id }}: {{ selectedTeam.title }} (
					<a
						href="#"
						@click.prevent="router.push({ name: 'Organizations', params: { id: selectedTeam.orgId } })"
					>
						{{ selectedTeam.orgTitle }}
					</a>
					)
				</template>
				<a
					v-else-if="selectedOrg"
					href="#"
					@click.prevent="router.push({ name: 'Organizations', params: { id: selectedOrg.id } })"
				>
					Organization
					{{ selectedOrg.id }}: {{ selectedOrg.title }}
				</a>
			</div>

			<DataTable :items="folderList" class="ba mt-5">
				<template v-slot:thead>
					<tr>
						<th>rootId</th>
						<th>Content Type</th>
						<th>Nodes</th>
						<th>Links from</th>
					</tr>
				</template>
				<template v-slot:tbody="{ item }">
					<tr
						@click="(e) => showContextMenu(item.id, e)"
						:class="{ selected: selectedFolder.id === item.id }"
					>
						<td class="ts">{{ item.id }}</td>
						<td>{{ getType(item.type) }}</td>
						<td>{{ item.nodes }}</td>
						<td>
							<div v-for="(link, indx) in item.links" :key="indx" class="outLink flx aln-cntr">
								{{ link.path }} ➡️&nbsp;
								<template v-if="link.userId">
									<b>User:</b>
									&nbsp;
									<a
										href="#"
										@click.prevent="router.push({ name: 'Users', params: { id: link.userId } })"
									>
										{{ link.objectName }}
									</a>
								</template>
								<template v-else-if="link.teamId">
									<b>Team:</b>
									&nbsp;
									<a
										href="#"
										@click.prevent="
											router.push({ name: 'Organizations', params: { id: link.teamOrgId } })
										"
									>
										{{ link.objectName }}
									</a>
								</template>
								<template v-else-if="link.orgId">
									<b>Organization:</b>
									&nbsp;
									<a
										href="#"
										@click.prevent="
											router.push({ name: 'Organizations', params: { id: link.orgId } })
										"
									>
										{{ link.objectName }}
									</a>
								</template>
								<button class="ml-5 x" @click.stop="unlink(link)" />
							</div>
						</td>
					</tr>
				</template>
			</DataTable>

			<div class="flx aln-cntr mv-10 pl-5">
				Links to
				<template v-if="selectedUser">
					User
					{{ selectedUser.id }}: {{ selectedUser.firstname }} {{ selectedUser.lastname }} &mdash;
					{{ selectedUser.email }}
				</template>
				<template v-else-if="selectedTeam">
					Team
					{{ selectedTeam.id }}: {{ selectedTeam.title }} ({{ selectedTeam.orgTitle }})
				</template>
				<template v-else-if="selectedOrg">
					Organization
					{{ selectedOrg.id }}: {{ selectedOrg.title }}
				</template>
			</div>

			<div class="links">
				<div>
					<header>Exercises</header>
					<LinkTo v-for="(link, indx) in exerciseLinks" :key="indx" :link="link" @unlink="unlink" />
				</div>

				<div>
					<header>Templates</header>
					<LinkTo v-for="(link, indx) in templateLinks" :key="indx" :link="link" @unlink="unlink" />
				</div>

				<div>
					<header>Education</header>
					<LinkTo v-for="(link, indx) in educationLinks" :key="indx" :link="link" @unlink="unlink" />
				</div>

				<div>
					<header>Outcomes</header>
					<LinkTo v-for="(link, indx) in outcomeLinks" :key="indx" :link="link" @unlink="unlink" />
				</div>
			</div>
		</template>

		<Transition name="slideDown">
			<ContextMenu v-if="contextMenu" :pos="contextMenu" @close="contextMenu = false">
				<button @click="moveNode(selectedFolder.id)">Move Node</button>
				<button @click="createLink(selectedFolder)">Create Link</button>
			</ContextMenu>
		</Transition>
	</div>
</template>

<script>
	import { debounce } from "lodash"
	import { ref, computed, watch, onMounted } from "vue"
	import { useRouter, useRoute } from "vue-router"
	import vSelect from "vue-select"
	import { api, alertsList, modals, folders, orgs, teams, users } from "@/store"
	import { ContextMenu, DataTable } from "@/components"
	import ChooseUser from "../ChooseUser"
	import ChooseTeam from "../ChooseTeam"
	import ChooseOrg from "../ChooseOrg"
	import ChooseNode from "./ChooseNode"
	import LinkTo from "./LinkTo"

	export default {
		name: "FolderList",
		components: { ContextMenu, DataTable, LinkTo, vSelect },
		props: {},
		setup(props) {
			const modalStore = modals()
			const alerts = alertsList()
			const router = useRouter()
			const route = useRoute()

			// users
			const userStore = users()
			const userSearch = ref("")
			const userIds = ref([])
			const selectedUser = computed({
				get() {
					const userId = +route.query.userId || null
					if (userId && !userStore.users.value[userId]) userStore.fetchUser(userId)
					return userId ? userStore.users.value[userId] : null
				},
				set(v) {
					if (v && v.id) router.push({ query: { userId: v.id, orgId: undefined, teamId: undefined } })
					else router.push({ query: { userId: undefined } })
				}
			})
			const userList = computed(() => userIds.value.map((id) => userStore.users.value[id]))
			const fetchUsers = debounce(async (search) => {
				const result = await userStore.fetchUsers({ search: search || "" })
				if (result) userIds.value = result.ids
			}, 500)
			watch(userSearch, fetchUsers)
			watch(selectedUser, (v) => {
				if (v) fetchFolders()
			})

			// teams
			const teamStore = teams()
			const teamSearch = ref("")
			const teamIds = ref([])
			const selectedTeam = computed({
				get() {
					const teamId = +route.query.teamId || null
					if (teamId && !teamStore.teams.value[teamId]) teamStore.fetchTeam(teamId)
					return teamId ? teamStore.teams.value[teamId] : null
				},
				set(v) {
					if (v && v.id) router.push({ query: { teamId: v.id, orgId: undefined, userId: undefined } })
					else router.push({ query: { teamId: undefined } })
				}
			})
			const teamList = computed(() => teamIds.value.map((id) => teamStore.teams.value[id]))
			const fetchTeams = debounce(async (search) => {
				const result = await teamStore.fetchTeams({ search: search || "" })
				if (result) teamIds.value = result.ids
			}, 500)
			watch(teamSearch, fetchTeams)
			watch(selectedTeam, (v) => {
				if (v) fetchFolders()
			})

			// orgs
			const orgStore = orgs()
			const orgSearch = ref("")
			const orgIds = ref([])
			const selectedOrg = computed({
				get() {
					const orgId = +route.query.orgId || null
					if (orgId && !orgStore.orgs.value[orgId]) orgStore.fetchOrg(orgId)
					return orgId ? orgStore.orgs.value[orgId] : null
				},
				set(v) {
					if (v && v.id) router.push({ query: { orgId: v.id, teamId: undefined, userId: undefined } })
					else router.push({ query: { orgId: undefined } })
				}
			})
			const orgList = computed(() => orgIds.value.map((id) => orgStore.orgs.value[id]))
			const fetchOrgs = debounce(async (search) => {
				const result = await orgStore.fetchOrgs({ search: search || "" })
				if (result) orgIds.value = result.ids
			}, 500)
			watch(orgSearch, fetchOrgs)
			watch(selectedOrg, (v) => {
				if (v) fetchFolders()
			})

			// folders
			const folderStore = folders()
			const folderIds = ref([])
			const folderList = computed(() => folderIds.value.map((id) => folderStore.folders.value[id]))
			const exerciseLinks = computed(() => folderStore.links.value.filter((l) => l.type === "exerciseFolder"))
			const templateLinks = computed(() => folderStore.links.value.filter((l) => l.type === "setFolder"))
			const educationLinks = computed(() => folderStore.links.value.filter((l) => l.type === "educationFolder"))
			const outcomeLinks = computed(() => folderStore.links.value.filter((l) => l.type === "outcomeFolder"))
			const fetchFolders = async () => {
				const filter = {}
				if (selectedUser.value) filter.userId = selectedUser.value.id
				else if (selectedTeam.value) filter.teamId = selectedTeam.value.id
				else if (selectedOrg.value) filter.orgId = selectedOrg.value.id
				else return
				const result = await folderStore.fetchFolders(filter)
				if (result) {
					folderIds.value = result.ids
				}
			}
			const selectedFolder = ref({})

			// contextMenu
			const contextMenu = ref(false)
			const showContextMenu = (folderId, { clientX, clientY }) => {
				selectedFolder.value = folderStore.folders.value[folderId]
				contextMenu.value = { x: clientX, y: clientY }
			}

			const moveNode = async (rootId) => {
				const selectedNode = await modalStore.open.component({
					component: ChooseNode,
					props: { rootId },
					size: "sm"
				})
				if (!selectedNode) return
				const index = await modalStore.open.dialog({
					header: "Choose destination",
					body: `Move Node to User, Team, or Organization?`,
					buttons: [{ label: "User" }, { label: "Team" }, { label: "Organization" }]
				})
				if (index === undefined) return
				const targetType = ["User", "Team", "Organization"][index]
				const target = await modalStore.open.component({
					component: [ChooseUser, ChooseTeam, ChooseOrg][index],
					size: "sm"
				})
				if (!target) return
				const confirm = await modalStore.open.dialog({
					header: "Confirm Move Node",
					body: `
						You are about to move the following:<br /><br />
						<b>Node:</b> ${selectedNode.title} (${getType(selectedFolder.value.type)})<br />
						<b>From 
						${
							selectedUser.value
								? `User:</b> ${selectedUser.value.firstname} ${selectedUser.value.lastname} (${selectedUser.value.email})`
								: ""
						}
						${selectedTeam.value ? `Team:</b> ${selectedTeam.value.title} (${selectedTeam.value.orgTitle})` : ""}
						${selectedOrg.value ? `Organization:</b> ${selectedOrg.value.title}` : ""}
						<br />
						<b>To ${targetType}:</b> 
						${targetType === "User" ? `${target.firstname} ${target.lastname} (${target.email})` : ""}
						${targetType === "Team" ? `${target.title} (${target.orgTitle})` : ""}
						${targetType === "Organization" ? `${target.title}` : ""}
						<br /><br />
						<b>Note:</b><br />
						Node will be inserted @ top-level<br />
					`,
					buttons: [{ label: "Cancel" }, { label: "Confirm" }]
				})
				if (confirm) {
					const success = await api.put(
						`admin/folders/${selectedNode.id}/${targetType.toLowerCase()}/${target.id}`,
						{ json: { title: selectedNode.title } }
					)
					if (success) {
						alerts.push("Node moved", "pos")
						fetchFolders()
					}
				}
			}

			const createLink = async (folder) => {
				const selectedNode = await modalStore.open.component({
					component: ChooseNode,
					props: { rootId: folder.id },
					size: "sm"
				})
				if (!selectedNode) return
				const index = await modalStore.open.dialog({
					header: "Choose target",
					body: `Link to User, Team, or Organization?`,
					buttons: [{ label: "User" }, { label: "Team" }, { label: "Organization" }]
				})
				if (index === undefined) return
				const targetType = ["User", "Team", "Organization"][index]
				const target = await modalStore.open.component({
					component: [ChooseUser, ChooseTeam, ChooseOrg][index],
					size: "sm"
				})
				const confirm = await modalStore.open.dialog({
					header: "Confirm Create Link",
					body: `
						You are about to link the following:<br /><br />
						<b>${getType(folder.type)} Folder:</b> ${folder.id === selectedNode.id ? "Root" : selectedNode.title} &mdash; 
						${
							selectedUser.value
								? `${selectedUser.value.firstname} ${selectedUser.value.lastname} (${selectedUser.value.email})`
								: ""
						}
						${selectedTeam.value ? `${selectedTeam.value.title}` : ""}
						${selectedOrg.value ? `${selectedOrg.value.title}` : ""}
						<br />
						<b>To ${targetType}:</b> 
						${targetType === "User" ? `${target.firstname} ${target.lastname} (${target.email})` : ""}
						${targetType === "Team" ? `${target.title} (${target.orgTitle})` : ""}
						${targetType === "Organization" ? `${target.title}` : ""}
						<br /><br />
						<b>Note:</b><br />
						A folder can only be linked to an object outside the scope of the folder's organization.<br />
					`,
					buttons: [{ label: "Cancel" }, { label: "Confirm" }]
				})
				if (confirm) {
					const success = await api.post(
						`admin/folders/${selectedNode.id}/link/${targetType.toLowerCase()}/${target.id}`
					)
					if (success) {
						alerts.push("Folder linked", "pos")
						fetchFolders()
					}
				}
			}

			const unlink = async (link) => {
				const confirm = await modalStore.open.dialog({
					header: "Break link",
					body: `You are about to unlink the following:<br /><br />${link.objectName}`,
					buttons: [{ label: "Cancel" }, { label: "Ok" }]
				})
				if (confirm) {
					const success = await api.del(`admin/folders/links/${link.id}`)
					if (success) {
						alerts.push("Unlinked", "pos")
						fetchFolders()
					}
				}
			}

			const getType = (v) => {
				if (v === "setFolder") return "Templates"
				else if (v === "exerciseFolder") return "Exercises"
				else if (v === "outcomeFolder") return "Outcomes"
				else if (v === "educationFolder") return "Education"
			}

			fetchUsers()
			fetchTeams()
			fetchOrgs()
			Promise.all([fetchUsers.flush(), fetchTeams.flush(), fetchOrgs.flush()]).then(fetchFolders)

			return {
				router,
				selectedUser,
				userSearch,
				userList,
				selectedTeam,
				teamSearch,
				teamList,
				selectedOrg,
				orgSearch,
				orgList,
				folderList,
				selectedFolder,
				contextMenu,
				showContextMenu,
				moveNode,
				createLink,
				unlink,
				getType,

				exerciseLinks,
				templateLinks,
				outcomeLinks,
				educationLinks
			}
		}
	}
</script>

<style lang="scss">
	.folders {
		.optClinicName,
		.optEmail {
			color: $clr-dark;
			text-decoration: italic;
		}
		.outLink:hover {
			background: $clr-fcs;
		}
		.links {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: $atom * 5;
			header {
				font-weight: bold;
				color: $clr-prim;
			}
			> div {
				padding: $atom * 5;
				background: #fff;
				border: 1px solid $clr-brdr;
				> div:hover {
					background: $clr-fcs;
				}
			}
		}
	}
</style>
