<template>
	<div class="header">Choose Node</div>
	<div class="nodes body">
		<Tree :tree="tree" @choose="(v) => (selectedNode = v)" />
	</div>
	<div class="footer">
		<button @click="close()">Close</button>
		<button v-if="selectedNode" @click="close(selectedNode)" class="bgGrdPos">{{ selectedNode.title }}</button>
	</div>
</template>

<script>
	import { ref } from "vue"
	import { api } from "@/store"
	import Tree from "./Tree"

	export default {
		name: "ChooseNode",
		components: { Tree },
		props: {
			rootId: { type: Number, required: true }
		},
		emits: ["close"],
		setup(props, cntxt) {
			const selectedNode = ref(null)
			const tree = ref({})
			api.get(`admin/folders/${props.rootId}`).then((result) => (tree.value = result))
			const close = (result) => cntxt.emit("close", result)
			return {
				tree,
				close,
				selectedNode
			}
		}
	}
</script>

<style lang="scss">
	.nodes {
		.title:hover {
			background: $clr-fcs;
			cursor: pointer;
			& ~ div {
				background: $clr-fcs;
				cursor: pointer;
			}
		}
	}
</style>
