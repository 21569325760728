<template>
	<div class="header">Choose User</div>
	<div class="chooseUser body">
		<vSelect
			v-model="selectedUser"
			@search="(v) => (userSearch = v)"
			:options="userList"
			label="label"
			placeholder="User..."
			class="ba"
		>
			<template #option="option">
				<span
					>{{ option.lastname }}, {{ option.firstname }}
					<span class="optEmail">{{ option.email }}</span></span
				>
			</template>
			<template #selected-option="{ firstname, lastname }">{{ lastname }}, {{ firstname }}</template>
		</vSelect>
	</div>
	<div class="footer">
		<button @click="$emit('close')">Close</button>
		<button v-if="selectedUser" @click="$emit('close', selectedUser)" class="bgGrdPos">Next</button>
	</div>
</template>

<script>
	import { debounce } from "lodash"
	import { ref, computed, watch } from "vue"
	import { users } from "@/store"
	import vSelect from "vue-select"

	export default {
		name: "ChooseUser",
		components: { vSelect },
		emits: ["close"],
		setup() {
			const userStore = users()
			const userSearch = ref("")
			const userIds = ref([])
			const selectedUser = ref(null)
			const userList = computed(() => userIds.value.map((id) => userStore.users.value[id]))
			const fetchUsers = debounce(async (search) => {
				const result = await userStore.fetchUsers({ search: search || "" })
				if (result) userIds.value = result.ids
			}, 500)
			watch(userSearch, fetchUsers)
			fetchUsers()

			return {
				selectedUser,
				userSearch,
				userList
			}
		}
	}
</script>

<style lang="scss">
	.chooseUser.body {
		z-index: 15;
	}
</style>
