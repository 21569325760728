<template>
	<div class="flx aln-cntr">
		<template v-if="link.userId">
			<b>User:</b>
			&nbsp;
			<a href="#" @click.prevent="router.push({ name: 'Users', params: { id: link.userId } })">
				{{ link.objectName }}
			</a>
		</template>
		<template v-else-if="link.teamId">
			<b>Team:</b>
			&nbsp;
			<a href="#" @click.prevent="router.push({ name: 'Organizations', params: { id: link.teamOrgId } })">
				{{ link.objectName }}
			</a>
		</template>
		<template v-else-if="link.orgId">
			<b>Organization:</b>
			&nbsp;
			<a href="#" @click.prevent="router.push({ name: 'Organizations', params: { id: link.orgId } })">
				{{ link.objectName }}
			</a>
		</template>
		&nbsp;&mdash;{{ link.path }}
		<button class="ml-5 x" @click="$emit('unlink', link)" />
	</div>
</template>

<script>
	import { useRouter, useRoute } from "vue-router"

	export default {
		name: "LinkTo",
		props: { link: { type: Object, required: true } },
		setup() {
			const router = useRouter()
			return { router }
		}
	}
</script>
